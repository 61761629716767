import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
//import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

//import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

// import SupportIconImage from "../../images/support-icon.svg";
// import ShieldIconImage from "../../images/shield-icon.svg";
// import CustomizeIconImage from "../../images/customize-icon.svg";
// import FastIconImage from "../../images/fast-icon.svg";
// import ReliableIconImage from "../../images/reliable-icon.svg";
// import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-green-800 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-green-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-green-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 text-green-500 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      //imageSrc: ShieldIconImage,
      title: "Cloud Launchpad",
      description: "From startups to global enterprises, Cloud Launchpad looks to reduce common errors companies experience establishing their cloud foundations. We also help companies build the internal expertise and technical experience they need to own, operate, and continue to evolve on their own cloud environment to meet their new technology goals."
    },
    { //imageSrc: SupportIconImage, 
      title: "Generative AI",
      description: "Generative AI has garnered a lot of attention and excitement, promising transformational outcomes for today’s businesses. At Infynitee, we bridge the gap between the heightened attention around Generative AI and its tangible benefits for your business."
     },
    { //imageSrc: CustomizeIconImage, 
      title: "Engineering on Demand",
      description: "infynitee’s Engineering on Demand offering is designed to proactively enhance your public cloud (Amazon Web Services (AWS), Microsoft Azure, or Google Cloud) platform’s security, reliability, performance, cost, and operational best practices postures."
    },
    { //imageSrc: ReliableIconImage, 
      title: "Kubernetes Enablement",
    description: "Infynitee prides itself as the enabler of cloud-native adoption, and Kubernetes plays a key role on the cloud-native adoption journey to maximize speed, cost-efficiencies and scalability."
    },
    { //imageSrc: FastIconImage, 
      title: "Managed Public Cloud",
      description: "Ensuring your cloud environment is optimized and operating reliably 24x7x365 doesn’t have to be a burden on your internal resources. Our Customer Reliability Engineering (CRE) focuses on the availability and reliability of your public cloud environments through incident-driven, operational, and technical support—backed by world-class managed cloud engineering expertise."
    },
    { //imageSrc: SimpleIconImage, 
      title: "App and Database Modernization", 
      description: "With database and app modernization, you can migrate your legacy applications and business processes into the cloud while simultaneously adopting the latest cloud-native technologies."
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Professional <span tw="text-green-500">Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              {/* <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" tw="bg-green-500" />
              </span> */}
              <span className="textContainer">
                <span className="title">{card.title || "Cloud Launchpad"}</span>
                <p className="description">
                  {card.description || "From startups to global enterprises, Cloud Launchpad looks to reduce common errors companies experience establishing their cloud foundations. We also help companies build the internal expertise and technical experience they need to own, operate, and continue to evolve on their own cloud environment to meet their new technology goals."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
