import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
//import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
//import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
//import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 text-green-400 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "",
  heading = <>Why Work <span tw="text-primary-500">With Us</span></>,
  description = "As one of Canada’s only multi-cloud experts (AWS Premier Partner, Microsoft Azure, Google Cloud), OpsGuru is uniquely qualified to help you on your cloud journey.",

}) => {
  const blogPosts = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      // author: "Adam Wathan",
      // category: "SEO",
      title: "Skilled at Unlocking Funding Programs",
      description: "As a multi-cloud partner, OpsGuru has extensive knowledge of available funding programs to help you de-risk your digital transformation and maximize your chances of receiving funding.",
      url: "#"
    },
    {
      imageSrc:
        "https://plus.unsplash.com/premium_photo-1683134027352-56d41226194e?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NzN8fGNsb3VkJTIwY29tcHV0aW5nfGVufDB8fDB8fHww",
      // author: "Owais Khan",
      // category: "Advertising",
      title: "Build your Internal Cloud Capabilities",
      description: "We provide not only a technical solution but programs to steadily build your internal know-how to develop your Cloud Center of Excellence and continually extend, expand, and evolve your business.",
      url: "#"
    },
    {
      imageSrc:
        "https://plus.unsplash.com/premium_photo-1682145181120-73cfdfc8a36d?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTd8fGNsb3VkJTIwY29tcHV0aW5nfGVufDB8fDB8fHww",
      // author: "Steve Schoger",
      // category: "Social Media",
      title: "Battle-Tested Cloud Services",
      description: "We have spent years developing and testing our proprietary cloud consulting services which utilize prescriptive best practices to help companies like yours take full advantage of cloud computing.",
      url: "#"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Details>
                  <MetaContainer>
                    <Meta>
                      {/* <UserIcon />
                      <div>{post.author}</div> */}
                    </Meta>
                    <Meta>
                      {/* <TagIcon />
                      <div>{post.category}</div> */}
                    </Meta>
                  </MetaContainer>
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  {/* <Link href={post.url}>Read Post</Link> */}
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
