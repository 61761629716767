import React from "react";
import tw from "twin.macro";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
//import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import Services from "components/features/ThreeColSimple.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

import MainFeature from "components/features/TwoColWithButton.js";
//import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnDark.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"


export default () => {
  //const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
 // const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  //const Description = tw.span`inline-block mt-8`;
  //const imageCss = tw`rounded-4xl`;

  return (
    <AnimationRevealPage>
      <Hero />
      <Features />
      <Services
        heading={
          <>
            Amazing <HighlightedText>Services.</HighlightedText>
          </>
        }
        cards={[
          {
            //imageSrc: shopIconImageSrc,
            title: "Professional Services",
            description: "Experienced, cross-functional software engineering experts for hire.",
            url: "#"
          },
          {
            //imageSrc: chefIconImageSrc,
            title: "Support Services",
            description: "Unblock your developers with world-class, certified, support services.",
            url: "#"
          },
          {
            //imageSrc: celebrationIconImageSrc,
            title: "Managed Services",
            description: "Managed Hosting and world-class Software Development tools delivered as a Service.",
            url: "#"
          }
        ]}

        //imageContainerCss={tw`p-2!`}
        //imageCss={tw`w-20! h-20!`}
      />
      {/* <Pricing /> */}
      <FeatureStats/>
      <MainFeature 
        subheading="Mangement Services"
        heading="Software Development Managers"
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false}
        description="Software development projects are complex, balancing developer resources, technical issues, software maintenance and strategic outcomes can quickly be a source of frustration, creating bottlenecks and impact team productivity. Eliminate software maintenance, improve CICD capabilities with our Managed, Support and Professional services."
      />
      <MainFeature 
        subheading="Support Services"
        heading="Heads and C-Level Executives"
        imageSrc={serverSecureIllustrationImageSrc}
        buttonRounded={false}
        textOnLeft={false}
      />
      <Testimonial />
      <FAQ />
      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  );
}
